import SectionTitle from "../../../components/SectionTitle/SectionTitle"
import styles from './Main.module.scss'
import { useTranslation } from "react-i18next"
import { useState, useEffect } from 'react'
import getRequest from "../../../helpers/getRequest"

import salesNetwork1 from '../../../img/sales-network-1.png'
import salesNetwork2 from '../../../img/sales-network-2.png'
import salesNetwork3 from '../../../img/sales-network-3.png'
import postRequest from "../../../helpers/postRequest"
import copyIcon from '../../../img/copy.svg'
import { copyText } from "../../../helpers/copyText"

const Main = ({ role }) => {
    const { t } = useTranslation()
    const [linkCopied, setLinkCopied] = useState(false)
    const [salesNetworkImage, setSalesNetworkImage] = useState('')
    const [createSaleLink, setCreateSaleLink] = useState('')

    const handleCopyLink = () => {
        copyText(createSaleLink);

        setLinkCopied(true);
        const timer = setTimeout(() => {
            setLinkCopied(false);
        }, [2000]);

        return () => {
            clearTimeout(timer);
        };
    };

    useEffect(() => {
        getRequest("v1/statistics/dashboard/sale").then((res) => {
            if (res.data.level === 1) {
                setSalesNetworkImage(salesNetwork1)
            } else if (res.data.level === 2) {
                setSalesNetworkImage(salesNetwork2)
            } else if (res.data.level === 3) {
                setSalesNetworkImage(salesNetwork3)
            } else if (res.data.level === 4){
                window.location.href = "/admin/dashboard"
            }
        }
        );
    }, [])

    useEffect(() => {
        if (role === "SALE") {
            getRequest("v1/referrals/my-code")
                .then((response) => {
                    setCreateSaleLink(response.data.invite_link);
                })
                .catch((error) => console.log(error));
        }
        if (role === "HEAD_OF_SALES") {
            if (!localStorage.getItem("inviteRefCode")) {
                postRequest("v1/referral-invites")
                    .then((response) => {
                        setCreateSaleLink(response.data.invite_link);
                        localStorage.setItem("inviteRefCode", response.data.invite_link);
                    })
                    .catch((error) => console.log(error));
            } else {
                setCreateSaleLink(localStorage.getItem("inviteRefCode"));
            }
        }

    }, [role]);
    return <section className={styles.network}>
        <SectionTitle text={t('Build your own sales network now and earn money from every sale made by your partners.')} />
        <div className={styles.network__inner}>
            <img src={salesNetworkImage} alt="sales network" />
            <div className={styles.network__invite}>
                <p>{t('Invite sales partners now')}</p>
                <div>
                    <input type="text" value={createSaleLink} />
                    <button
                        onClick={() => handleCopyLink()}
                        className={styles.copyButton}
                    >
                        <img src={copyIcon} alt="copy" />
                        {linkCopied && <span className={styles.tooltip}>{t('Copied to clipboard')}</span>}
                    </button>
                </div>
            </div>
        </div>
    </section>
}

export default Main