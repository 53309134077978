import React, { useEffect, useState } from "react";
import styles from "./Newspaper.module.scss";
import news1 from "../../../../img/news1.png";
import news3 from "../../../../img/news3.webp";
import news4 from "../../../../img/news4.webp";
import news2 from "../../../../img/news2.webp";
import news5 from "../../../../img/news5.png";
import news6 from "../../../../img/news6.svg";
import { useTranslation } from "react-i18next";
import Card from "./components/Card";
import useMeasure from "react-use-measure";
import { animate, useMotionValue, motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
const newspapers = [
  {
    id: 4,
    img: news6,
    link: "https://www.wallstreet-online.de/nachricht/18650097-news-wachstumsmarkt-cannabis",
  },
  {
    id: 5,
    img: news2,
    link: 'https://www.bild.de/regional/mecklenburg-vorpommern/mecklenburg-vorpommern-news/cannabis-legalisiert-an-der-ostsee-wird-jetzt-marihuana-angebaut-87616272.bild.html'
  },
  {
    id: 1,
    img: news3,
    link: "https://www.ndr.de/nachrichten/mecklenburg-vorpommern/Anklam-Erste-Cannabisfarm-in-MV-entsteht,cannabisfarm100.html",
  },
  {
    id: 2,
    img: news4,
    link: "https://www.tagesschau.de/inland/regional/mecklenburgvorpommern/ndr-anklam-erste-cannabisfarm-in-mv-entsteht-100.html",
  },
  {
    id: 3,
    img: news1,
    link: "https://www.zeit.de/2024/14/cannabis-legalisierung-plantage-relzow",
  },
  {
    id: 0,
    img: news5,
    link: "https://berlinboxx.de/bundesgesundheitsminister-lauterbach-bei-berliner-wirtschaftsgespraechen.html",
  },
];

const Newspaper = () => {
  const matches = useMediaQuery("(max-width: 768px)");

  const { t } = useTranslation();

  const FAST_DURATION = 25;
  const SLOW_DURATION = 1000;

  const [duration, setDuration] = useState(FAST_DURATION);

  const [ref, { width }] = useMeasure();
  const xTranslation = useMotionValue(0);

  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    let controls;
    let finalPosition = matches ? -width * 2 - 8 : -width / 2 - 8;

    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: duration * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: "linear",
        duration: duration,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
  }, [xTranslation, width, duration, rerender, mustFinish, matches]);

  return (
    <section className={styles.newspaper}>
      <div className="container-fluid-2">
        <div className={styles.newspaper__title}>{t("Known from:")}</div>
        <div className={styles.newspaper__outer}>
          <motion.div
            className={styles.newspaper__wrapper}
            ref={ref}
            style={{ x: xTranslation }}
            onHoverStart={() => {
              if (!matches) {
                setMustFinish(true);
                setDuration(SLOW_DURATION);
              }
            }}
            onHoverEnd={() => {
              if (!matches) {
                setMustFinish(true);
                setDuration(FAST_DURATION);
              }
            }}
            onTouchStart={() => {
              if (matches) {
                setMustFinish(true);
                setDuration(SLOW_DURATION);
              }
            }}
            onTouchEnd={() => {
              if (matches) {
                setMustFinish(true);
                setDuration(FAST_DURATION);
              }
            }}
          >
            {matches
              ? [...newspapers, ...newspapers].map((item, idx) => (
                  <Card key={idx} item={item} />
                ))
              : [...newspapers, ...newspapers, ...newspapers, ...newspapers].map(
                  (item, idx) => (
                    <Card key={idx} item={item} />
                  )
                )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Newspaper;
