/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./HeaderCommon.module.scss";
import "../../styling/common.css";
import logo from "../../img/logo.png";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import Button from "../Button/Button";
import menu from "../../img/menu.svg";
import x from "../../img/x.svg";
import de from "../../img/de.svg";
import en from "../../img/en.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../..";
import { useObserver } from "mobx-react-lite";
import wallet from "../../img/wallet.svg";
import weed from "../../img/weed.svg";
import HelpIcon from "../../img/IconComponents/HelpIcon";
import GearIcon from "../../img/IconComponents/GearIcon";
import Bullhorn from "../../img/IconComponents/Bullhorn";
import getRequest from "../../helpers/getRequest";
import Stats from "../Header/Components/Stats/Stats";
import Logout from "../Logout/Logout";
import AsideLink from "../AdminAside/components/AsideLink/AsideLink";
import AnchorLink from "../Aside/components/AnchorLink/AnchorLink";
import BuyPlantsIcon from "../../img/IconComponents/BuyPlantsIcon";
import Cross from "../../img/IconComponents/Cross";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import DashboardIcon from "../../img/IconComponents/DashboardIcon";
import ViewPlantsIcon from "../../img/IconComponents/ViewPlantsIcon";
import LocationIcon from "../../img/IconComponents/LocationIcon";
import FinancesIcon from "../../img/IconComponents/FinancesIcon";
import NewsIcon from "../../img/IconComponents/NewsIcon";
import ShopIcon from "../../img/IconComponents/ShopIcon";
import MallIcon from "../../img/IconComponents/MallIcon";
import account from "../../img/account.png";
import HomeIcon from "../../img/IconComponents/HomeIcon";
import { API_URL } from "../../http";
import axios from "axios";
import MenuLink from "../Header/Components/MenuLink/MenuLink";
const HeaderCommon = ({ currentLang, setCurrentLang }) => {
  const [user, setUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [roleRegister, setRoleRegister] = useState("client");
  const [isOpenClientMenu, setIsOpenClientMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [profilePhotoUser, setProfilePhotoUser] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [userData, setUserData] = useState({});
  const [plantBalance, setPlantBalance] = useState(null);
  const navigate = useNavigate();
  const [salesLevel, setSalesLevel] = useState(0)
  const [headerData, setHeaderData] = useState([
    // {
    //   icon: wallet,
    //   value: "0.00",
    //   type: "EUR",
    //   id: 1,
    // },
    {
      icon: weed,
      value: "0.00",
      type: "g",
      id: 2,
    },
  ]);
  const [role, setRole] = useState(null);

  const location = useLocation();

  const { t, i18n } = useTranslation();

  const { store } = useContext(Context);
  const { isAuth, profilePhoto } = useObserver(() => ({
    isAuth: store.isAuth,
    profilePhoto: store.profilePhoto,
  }));

  if (isOpen) {
    // document.getElementById("root").style.overflow = "hidden";
    document.body.style.overflow = "hidden";
  } else {
    // document.getElementById("root").style.overflow = "visible";
    document.body.style.overflow = "visible";
  }

  const changeLanguageDe = () => {
    i18n.changeLanguage("DE");
    setCurrentLang({ lang: "DE", image: de });
    localStorage.setItem("i18n", "DE");
  };

  const changeLanguageEn = () => {
    i18n.changeLanguage("EN");
    setCurrentLang({ lang: "EN", image: en });
    localStorage.setItem("i18n", "EN");
  };

  useEffect(() => {
    if (!!isAuth) {
      setUser(true);

      getRequest("v1/auth/whoami")
        .then((response) => {
          setRole(response.data?.user_role);
          setUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      getRequest("v1/finances/data").then((res) => {
        setPlantBalance(+res.data.plants_count);
      });
    } else {
      setUser(false);
    }
  }, [isAuth, role]);

  useEffect(() => {
    if (role === "CLIENT") {
      axios({
        method: "get",
        url: `${API_URL}/v1/harvest/available`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        setHeaderData([
          // {
          //   icon: wallet,
          //   value: (response.data.unsold_harvest * 2.5).toFixed(2) || "0.00",
          //   type: "EUR",
          //   id: 1,
          // },
          {
            icon: weed,
            value: response.data.unsold_harvest.toFixed(2) || "0.00",
            type: "g",
            id: 2,
          },
        ]);
      });
    }
    if (role === 'SALE') {
      getRequest("v1/statistics/dashboard/sale").then((res) =>
        setSalesLevel(res.data.level)
      );
    }
    // if (role === "SALE" || role === "HEAD_OF_SALES" || role === "MAIN_SALE") {
    //   getRequest("v1/finances/sales-area/data").then((response) =>
    //     setHeaderData([
    //       {
    //         icon: wallet,
    //         value: response.data?.current_balance.toFixed(2) || "0.00",
    //         type: "EUR",
    //         id: 1,
    //       },
    //     ])
    //   );
    // }
  }, [role]);

  useEffect(() => {
    if (location?.pathname?.includes("/tip")) {
      setRoleRegister("sale");
    } else if (
      !location?.pathname.includes("/tip") &&
      location?.search.includes("invite_code")
    ) {
      setRoleRegister("hs");
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.width > 576) {
      setIsOpen(false);
    }
  }, [windowSize]);

  useEffect(() => {
    getRequest("v1/user/billing-details").then((response) => {
      setUserName(response.data?.first_name);
      setUserLastName(response.data?.last_name);
    });
    getRequest("v1/local-files/profile-picture", true).then((response) => {
      setProfilePhotoUser(response.data);
    });
  }, [user]);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <header className={styles.header}>
      <div className="container-fluid">
        <div className={styles.header__wrapper}>
          <div className={styles.header__wrapper_logo}>
            <Link to={"/"}>
              <img className={styles.header__logo} src={logo} alt="logo" />
            </Link>
          </div>
          {!user ? (
            !location.pathname.includes("/register") ||
              location.pathname.includes("/register/referral") ? (
              <Button to="/register" text={t("Register now")} color="green" />
            ) : null
          ) : null}
          {user ? (
            <>
              {role &&
                role != "MAIN_SALE" &&
                role != "SALE" &&
                role != "HEAD_OF_SALES" ? (
                <div className={styles.header__user}>
                  <div className={styles.header__user__list}>
                    <AsideLink
                      path={"/dashboard"}
                      active={location.pathname.includes("/dashboard")}
                      text={t("Overview")}
                    />
                    {plantBalance > 0 ? (
                      <AsideLink
                        path={"/view"}
                        text={t("My tokens")}
                        active={location.pathname.includes("/view")}
                      />
                    ) : null}

                    <AsideLink
                      path={"/buy"}
                      text={t("Buy Tokens")}
                      active={
                        location.pathname === "/checkout" ||
                        location.pathname === "/buy"
                      }
                    />
                    <AsideLink
                      path={"/shop"}
                      active={location.pathname.includes("/shop")}
                      text={t("Shop")}
                    />
                    <AsideLink
                      path={"/location"}
                      active={location.pathname.includes("/location")}
                      text={t("Location")}
                    />
                    <AsideLink
                      path={"/finances"}
                      active={location.pathname.includes("/finances")}
                      text={t("My finances")}
                    />
                    <AsideLink
                      path={"/news"}
                      active={location.pathname.includes("/news")}
                      text={t("Recent news")}
                    />
                    <AsideLink path={"/contact"} text={t("Contact Us")} />
                    <AsideLink path={"/press"} text={t("Press")} />
                  </div>
                </div>
              ) : (
                <div className={styles.header__user}>
                  <div className={styles.header__user__list}>
                    <AsideLink
                      path={"/admin/dashboard"}
                      active={location.pathname.includes("/dashboard")}
                      text={t("Dashboard")}
                    />
                    <AsideLink
                      path={"/admin/finances"}
                      text={t("Finances")}
                      active={location.pathname.includes("/finances")}
                    />
                    <AsideLink
                      path={"/admin/documents"}
                      text={t("Sales Documents")}
                      active={location.pathname.includes("/finances")}
                    />
                    {salesLevel === 4 ? null :
                      <AsideLink
                        path={"/admin/network"}
                        text={t("Sales Network")}
                        active={location.pathname.includes("/admin/network")}
                      />
                    }
                    <AsideLink path={"/contact"} text={t("Contact Us")} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {(location.pathname === "/" ||
                location.pathname === "/advertisement" ||
                location.pathname.includes("/press") ||
                location.pathname.includes("/ad")) && (
                  <div className={styles.header__user}>
                    <div className={styles.header__user__list}>
                      <AnchorLink
                        active={location.hash === "#location"}
                        text={t("Location")}
                        path="/#location"
                      />
                      <AnchorLink
                        active={location.hash === "#cannabisToken"}
                        text={t("DECT-Token")}
                        path="/#cannabisToken"
                      />
                      <AnchorLink
                        active={location.hash === "#mission"}
                        text={t("Our mission")}
                        path="/#mission"
                      />
                      <AnchorLink
                        active={location.hash === "#faq"}
                        text={t("FAQs")}
                        path="/#faq"
                      />
                      <AnchorLink text={t("Contact Us")} path="/contact" />
                      <AsideLink path={"/press"} text={t("Press")} />
                    </div>
                  </div>
                )}
            </>
          )}
          {user ? (
            <div className={styles.mobile}>
              <AsideLink
                path={"/dashboard"}
                active={location.pathname.includes("/dashboard")}
                text={t("Overview")}
              />
            </div>
          ) : null}
          <div className={styles.header__info}>
            {user ? (
              <>
                {role &&
                  role != "MAIN_SALE" &&
                  role != "SALE" &&
                  role != "HEAD_OF_SALES" ? (
                  <div className={styles.header__right}>
                    <div className={styles.header__user__stats}>
                      {headerData.map((item) => {
                        const { icon, value, type, id } = item;
                        return (
                          <Stats
                            key={id}
                            icon={icon}
                            value={value}
                            type={type}
                          />
                        );
                      })}
                    </div>
                    <div className={styles.header__stats_btns}>
                      <Link to="/settings">
                        <GearIcon color="#B3B3B3" />
                      </Link>
                      <Link to="/help">
                        <HelpIcon color="#B3B3B3" />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className={styles.header__right}>
                    <div className={styles.header__user__stats}>
                      {headerData.map((item) => {
                        const { icon, value, type, id } = item;
                        return (
                          <Stats
                            key={id}
                            icon={icon}
                            value={value}
                            type={type}
                          />
                        );
                      })}
                    </div>
                    <div className={styles.header__stats_btns}>
                      <Link to="/admin/settings">
                        <GearIcon color="#B3B3B3" />
                      </Link>
                    </div>
                  </div>
                )}
              </>
            ) : null}
            <LangSwitcher
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />

            {user ? (
              <div className={styles.desktop}>
                <Logout />
              </div>
            ) : (
              <div className={styles.header__btns}>
                {roleRegister === "sale" || roleRegister === "hs" ? null : (
                  <Button text={t("Log in")} to="/login" />
                )}
                {roleRegister === "hs" ? null : (
                  <Button
                    text={t("Secure token now")}
                    color="green"
                    to="/register"
                  />
                )}
              </div>
            )}
            <button
              onClick={() => setIsOpen((prev) => !prev)}
              className={styles.header__mobileBtn}
            >
              <img src={menu} alt="menu" />
            </button>
          </div>
        </div>
      </div>
      {isOpen && !user && (
        <div className={styles.header__mobile}>
          <div className={styles.header__mobile_head}>
            <button
              onClick={() => setIsOpen((prev) => !prev)}
              className={styles.header__mobileBtn}
            >
              <img src={x} alt="x" />
            </button>
          </div>
          <div className={styles.header__mobile_body}>
            <img src={logo} alt="logo" />
            <div className={styles.header__mobile_inner}>
              {user ? null : (
                <div className={styles.header__mobile_btns}>
                  <Button
                    text={t("Log in")}
                    to="/login"
                    onClick={() => setIsOpen(false)}
                  />
                  <Button
                    text={t("Sign in")}
                    color="green"
                    to="/register"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              )}
              {user ? (
                <>
                  {role &&
                    role != "MAIN_SALE" &&
                    role != "SALE" &&
                    role != "HEAD_OF_SALES" ? (
                    <ul>
                      <li>
                        <Link to="/dashboard" onClick={handleLinkClick}>
                          {t("Overview")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/view" onClick={handleLinkClick}>
                          {t("My tokens")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/buy"
                          state={{
                            from: window.location.pathname,
                          }}
                          onClick={handleLinkClick}
                        >
                          {t("Buy Tokens")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop" onClick={handleLinkClick}>
                          {t("Shop")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/location" onClick={handleLinkClick}>
                          {t("Location")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/finances" onClick={handleLinkClick}>
                          {t("My finances")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/news" onClick={handleLinkClick}>
                          {t("Recent news")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <Link to="/admin/dashboard" onClick={handleLinkClick}>
                          {t("Dashboard")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/finances" onClick={handleLinkClick}>
                          {t("Finances")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </>
              ) : (
                <ul>
                  <li>
                    <Link to="/#location" onClick={handleLinkClick}>
                      {t("Location")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#legalization" onClick={handleLinkClick}>
                      {t("Legalization")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#mission" onClick={handleLinkClick}>
                      {t("Our mission")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/#faq" onClick={handleLinkClick}>
                      {t("FAQs")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={handleLinkClick}>
                      {t("Contact Us")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/press" onClick={handleLinkClick}>
                      {t("Press")}
                    </Link>
                  </li>
                </ul>
              )}
              <div className={styles.header__mobile_langs}>
                <div onClick={() => changeLanguageEn()}>
                  <img src={en} alt="en" />
                  EN
                </div>
                <div onClick={() => changeLanguageDe()}>
                  <img src={de} alt="de" />
                  DE
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && user && (
        <div className={cn(styles.header__menu)}>
          <div className={styles.header__menu_overflow}>
            <div className={styles.header__menu_header}>
              <div className={styles.header__menu_header_logo}>
                <img src={logo} alt="logo" width={163} />
              </div>

              <div className={styles.header__menu_header_wrapper}>
                <Link
                  to="/buy"
                  state={{
                    from: window.location.pathname,
                  }}
                  onClick={handleLinkClick}
                >
                  <BuyPlantsIcon color="#0F131E" />
                </Link>{" "}
                <button
                  onClick={() => setIsOpen(false)}
                  className={styles.header__menu_header_button}
                >
                  <Cross />
                </button>
              </div>
            </div>
            <div className={styles.header__menu_client}>
              <div className={styles.header__menu_client_header}>
                <div>
                  <div className={styles.header__menu_client_header_wrapper}>
                    <div className={styles.header__menu_client_image}>
                      {profilePhotoUser && profilePhotoUser.size ? (
                        <img
                          src={URL.createObjectURL(profilePhotoUser)}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className={
                            styles.settings__account_info_pict_uploaded
                          }
                          src={account}
                          alt="profile"
                        />
                      )}
                    </div>
                    <div>
                      <div className={styles.header__menu_client_name}>
                        {userName} {userLastName}
                      </div>
                      {role != "CLIENT" ? (
                        <div className={styles.header__menu_client_status}>
                          {role === "SALE" && t("Sale")}
                          {role === "HEAD_OF_SALES" && "Head of Sales"}
                          {role === "MAIN_SALE" && t("Top sale")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={styles.header__menu_client_right}>
                  <div className={styles.header__menu_switcher}>
                    <div onClick={() => changeLanguageEn()}>
                      <img src={en} alt="en" />
                    </div>
                    <div onClick={() => changeLanguageDe()}>
                      <img src={de} alt="de" />
                    </div>
                  </div>
                  <div
                    onClick={() => setIsOpenClientMenu((prev) => !prev)}
                    className={cn(
                      styles.header__menu_client_btn,
                      isOpenClientMenu
                        ? styles.header__menu_client_btn_active
                        : ""
                    )}
                  >
                    <ArrowDropdown />
                  </div>
                </div>
              </div>
              <div
                className={cn(
                  styles.header__menu_add,
                  isOpenClientMenu && role != "CLIENT"
                    ? styles.header__menu_add_active_sale
                    : "",
                  isOpenClientMenu && role === "CLIENT"
                    ? styles.header__menu_add_active
                    : ""
                )}
              >
                {role != "CLIENT" ? (
                  <Link
                    className={styles.header__menu_link}
                    to="/admin/settings"
                    onClick={handleLinkClick}
                  >
                    <GearIcon />
                    <div>{t("Settings")}</div>
                  </Link>
                ) : (
                  <>
                    <MenuLink
                      setIsHeaderMobileOpen={setIsOpen}
                      path="/settings"
                      icon={GearIcon}
                      text={t("Settings")}
                      active={location.pathname.includes("/settings")}
                    />
                    <Logout />
                  </>
                )}
              </div>
            </div>
            <div className={styles.header__menu_menu}>
              {role != "CLIENT" ? (
                <>
                  <Link
                    className={styles.header__menu_link}
                    to="/admin/dashboard"
                    onClick={handleLinkClick}
                  >
                    <DashboardIcon />
                    <div>{t("Dashboard")}</div>
                  </Link>
                  <Link
                    className={styles.header__menu_link}
                    to="/admin/finances"
                    onClick={handleLinkClick}
                  >
                    <FinancesIcon />
                    <div>{t("Finances")}</div>
                    <div className={styles.header__menu_link_finances}>
                      {headerData && headerData[0]?.value} EUR
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/"
                    text={t("Start page")}
                    icon={HomeIcon}
                  />
                  {/* Personal Area category */}
                  <p className={styles.header__menu_subheader}>
                    {t("Personal area")}
                  </p>
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/dashboard"
                    text={t("Overview")}
                    icon={DashboardIcon}
                    active={location.pathname.includes("/dashboard")}
                  />
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/view"
                    text={t("My tokens")}
                    // disabled={userData.tokens_count == 0}
                    icon={ViewPlantsIcon}
                    active={location.pathname.includes("/view")}
                  />
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/finances"
                    text={t("My finances")}
                    icon={FinancesIcon}
                    active={location.pathname.includes("/finances")}
                  />
                  {/* Buy/Sell Category */}
                  <p className={styles.header__menu_subheader}>
                    {t("Buy/Sell")}
                  </p>
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/buy"
                    text={t("Buy Tokens")}
                    icon={BuyPlantsIcon}
                    active={
                      location.pathname === "/checkout" ||
                      location.pathname === "/buy"
                    }
                  />

                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/marketplace"
                    icon={MallIcon}
                    text={t("Marketplace")}
                    active={location.pathname.includes("/marketplace")}
                  />

                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/shop"
                    icon={ShopIcon}
                    text={t("Shop")}
                    active={location.pathname.includes("/shop")}
                  />
                  {/* Information Category */}
                  <p className={styles.header__menu_subheader}>
                    {t("Information")}
                  </p>
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/news"
                    icon={NewsIcon}
                    text={t("Recent news")}
                    active={location.pathname.includes("/news")}
                  />
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/press"
                    icon={Bullhorn}
                    text={t("Press")}
                    active={location.pathname.includes("/press")}
                  />
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/help"
                    icon={HelpIcon}
                    text={t("Help")}
                    active={location.pathname.includes("/help")}
                  />
                  <MenuLink
                    setIsHeaderMobileOpen={setIsOpen}
                    path="/location"
                    icon={LocationIcon}
                    text={t("Location")}
                    active={location.pathname.includes("/location")}
                  />
                </>
              )}
            </div>
            <div className={styles.header__menu_footer}>
              <div className={styles.header__menu_footer_divider}></div>
              <MenuLink
                setIsHeaderMobileOpen={setIsOpen}
                path="/imprint"
                text={t("Imprint")}
                active={location.pathname.includes("/imprint")}
                additional
              />
              <MenuLink
                setIsHeaderMobileOpen={setIsOpen}
                path="/risk-information"
                text={t("Risk information")}
                active={location.pathname.includes("/risk-information")}
                additional
              />
              <MenuLink
                setIsHeaderMobileOpen={setIsOpen}
                path="/agb"
                text={t("AGB")}
                active={location.pathname.includes("/agb")}
                additional
              />
              <MenuLink
                setIsHeaderMobileOpen={setIsOpen}
                path="/privacy"
                text={t("Privacy Policy")}
                active={location.pathname.includes("/privacy")}
                additional
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default HeaderCommon;
