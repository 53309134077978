import React, { useState, useEffect } from "react";
import styles from "./Webcam.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import CameraVideo from "../../../../img/camplant.webp";
import camera1 from "../../../../img/camera1.webp";
import camera2 from "../../../../img/camera2.webp";
import camera3 from "../../../../img/camera3.webp";
import camera4 from "../../../../img/camera4.webp";
import getRequest from "../../../../helpers/getRequest";
import Table from "../../../Finances/Components/Table/Table";
import Status from "../../../../components/Status/Status";
import tokenNew from "../../../../img/tokenNew.webp";
import nftDesign2 from "../../../../img/nftDesign2.webp";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Main = () => {
  const [totalPlants, setTotalPlants] = useState(0);
  const [amountPlants, setAmountPlants] = useState(0);
  const [plantBalance, setPlantBalance] = useState(null);
  const [plantsTillMay, setPlantsTillMay] = useState([]);
  const [totalPlantsTillMay, setTotalPlantsTillMay] = useState(0);
  const [plantsJuneJuly, setPlantsJuneJuly] = useState([]);
  const [totalPlantsJuneJuly, setTotalPlantsJuneJuly] = useState(0);
  const [plantsFromAugust, setPlantsFromAugust] = useState([]);
  const [totalPlantsFromAugust, setTotalPlantsFromAugust] = useState(0);
  const [plantsBefore2025, setPlantsBefore2025] = useState([]);
  const [totalPlantsBefore2025, setTotalPlantsBefore2025] = useState(0);
  const [plantsIn2025, setPlantsIn2025] = useState([]);
  const [totalPlantsIn2025, setTotalPlantsIn2025] = useState(0);
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const statuses = [
    { text: t("Planting"), id: 1 },
    { text: t("Harvest"), id: 2 },
    { text: t("Growth"), id: 3 },
    { text: t("Drying"), id: 4 },
    { text: t("Flowering"), id: 5 },
    { text: t("Processing"), id: 6 },
  ];

  const statusDescription = [
    { text: t("The cannabis plants are planted") },
    { text: t("The cannabis plants are harvested") },
    { text: t("The cannabis plant grows") },
    { text: t("The cannabis plants are dried") },
    { text: t("The cannabis plants form flowers") },
    { text: t("The flowers are further processed and packaged") },
  ];

  useEffect(() => {
    getRequest("v1/finances/data")
      .then((response) => {
        // setPlantsTillMay([
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       return (
        //         (new Date(item.purchase_date).getMonth() <= 4 &&
        //           new Date(item.purchase_date).getFullYear() == 2024) ||
        //         new Date(item.purchase_date).getFullYear() < 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ),
        // ]);
        // setTotalPlantsTillMay(
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       return (
        //         (new Date(item.purchase_date).getMonth() <= 4 &&
        //           new Date(item.purchase_date).getFullYear() == 2024) ||
        //         new Date(item.purchase_date).getFullYear() < 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ).plants_count
        // );

        // setPlantsJuneJuly([
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       const month = new Date(item.purchase_date).getMonth();
        //       return (
        //         month >= 5 &&
        //         month <= 6 &&
        //         new Date(item.purchase_date).getFullYear() == 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ),
        // ]);

        // setTotalPlantsJuneJuly(
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       const month = new Date(item.purchase_date).getMonth();
        //       return (
        //         month >= 5 &&
        //         month <= 6 &&
        //         new Date(item.purchase_date).getFullYear() == 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ).plants_count
        // );

        // setPlantsFromAugust([
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       return (
        //         (new Date(item.purchase_date).getMonth() >= 7 &&
        //           new Date(item.purchase_date).getFullYear() == 2024) ||
        //         new Date(item.purchase_date).getFullYear() > 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ),
        // ]);

        // setTotalPlantsFromAugust(
        //   response.data.plantsDetails
        //     .filter((item) => {
        //       return (
        //         (new Date(item.purchase_date).getMonth() >= 7 &&
        //           new Date(item.purchase_date).getFullYear() == 2024) ||
        //         new Date(item.purchase_date).getFullYear() > 2024
        //       );
        //     })
        //     .reduce(
        //       (acc, val) => ({
        //         ...acc,
        //         total_amount: acc.total_amount + Number(val.total_amount),
        //         plants_count: acc.plants_count + Number(val.plants_count),
        //       }),
        //       { total_amount: 0, plants_count: 0 }
        //     ).plants_count
        // );

        console.log("plantsDetails\n",response.data.plantsDetails);

        setPlantsBefore2025([
          response.data.plantsDetails
            .filter((item) => {
              return new Date(item.created_at).getFullYear() < 2025;
            })
            .reduce(
              (acc, val) => ({
                ...acc,
                total_amount: acc.total_amount + Number(val.total_amount),
                plants_count: acc.plants_count + Number(val.plants_count),
              }),
              { total_amount: 0, plants_count: 0 }
            ),
        ]);

        setTotalPlantsBefore2025(
          response.data.plantsDetails
            .filter((item) => {
              return new Date(item.created_at).getFullYear() < 2025;
            })
            .reduce(
              (acc, val) => ({
                ...acc,
                total_amount: acc.total_amount + Number(val.total_amount),
                plants_count: acc.plants_count + Number(val.plants_count),
              }),
              { total_amount: 0, plants_count: 0 }
            ).plants_count
        );

        setPlantsIn2025([
          response.data.plantsDetails
            .filter((item) => {
              return new Date(item.created_at).getFullYear() == 2025;
            })
            .reduce(
              (acc, val) => ({
                ...acc,
                total_amount: acc.total_amount + Number(val.total_amount),
                plants_count: acc.plants_count + Number(val.plants_count),
              }),
              { total_amount: 0, plants_count: 0 }
            ),
        ]);

        setTotalPlantsIn2025(
          response.data.plantsDetails
            .filter((item) => {
              return new Date(item.created_at).getFullYear() == 2025;
            })
            .reduce(
              (acc, val) => ({
                ...acc,
                total_amount: acc.total_amount + Number(val.total_amount),
                plants_count: acc.plants_count + Number(val.plants_count),
              }),
              { total_amount: 0, plants_count: 0 }
            ).plants_count
        );







        setTotalPlants(
          response.data.plantsDetails.reduce(
            (acc, val) => ({
              ...acc,
              total_amount: acc.total_amount + Number(val.total_amount),
              plants_count: acc.plants_count + Number(val.plants_count),
            }),
            { total_amount: 0, plants_count: 0 }
          ).plants_count
        );

        setAmountPlants(
          response.data.plantsDetails.reduce(
            (acc, val) => ({
              ...acc,
              total_amount: acc.total_amount + Number(val.total_amount),
              plants_count: acc.plants_count + Number(val.plants_count),
            }),
            { total_amount: 0, plants_count: 0 }
          ).total_amount
        );
      })
      .catch((error) => {
        console.log(error);
      });
    getRequest("v1/dashboard/balance").then((response) => {
      setPlantBalance(response?.data?.plantsBalance);
    });
  }, []);

  return (
    <section className={styles.webcam}>
      <div className={styles.plants}>
        <div
          className={styles.plants__left}
          style={{
            flex:
              pathname.includes("/view") || pathname.includes("/finances")
                ? '1'
                : "0.35",
          }}
        >
          <h2>{t("My Tokens")}</h2>
          <div>
            <img src={tokenNew} alt="token" width={150} height={150} />
            <ul>
              <li>
                <span>{t("Quantity ")}:</span>
                <span>{totalPlants}</span>
              </li>
              <li>
                <span>{t("Value")}:</span>
                <span>{amountPlants.toLocaleString("de-DE")} €</span>
              </li>
              <li>
                <span>{t("Tradable")}:</span>
                <span>2025</span>
              </li>
              <li>
                <span>{t("Blockchain")}:</span>
                <span>ERC-20</span>
              </li>
              {/* <li>
                <span>{t("Total number of units")}:</span>
                <span>180,000</span>
              </li> */}
            </ul>
          </div>
        </div>
        {/* {if path /view then display */}
        {pathname.includes("/view") || pathname.includes("/finances") ? (
          <div
            className={styles.plants__right}
            style={{
              flex: '1'
            }}
          >
            <h2>{t("Access to planting site NFTs")}</h2>
            <div>
              <img src={nftDesign2} alt="token" width={150} height={150} />
              <div className={styles.plants__right_table}>
                <div className={styles.plants__right_row}>
                  <p>{t("Quantity ")}:</p>
                  {totalPlantsBefore2025 > 0 ? <p>{totalPlantsBefore2025}</p> : null}
                  {totalPlantsIn2025 > 0 ? <p>{totalPlantsIn2025}</p> : null}
                </div>
                <div className={styles.plants__right_row}>
                  <p>{t("Yield per harvest")}:</p>
                  {totalPlantsBefore2025 > 0 ? <p>50g</p> : null}
                  {totalPlantsIn2025 > 0 ? <p>25g</p> : null}
                </div>
                <div className={styles.plants__right_row}>
                  <p>{t("Yield per year")}:</p>
                  {totalPlantsBefore2025 > 0 ? <p>200g</p> : null}
                  {totalPlantsIn2025 > 0 ? <p>100g</p> : null}
                </div>
                <div className={styles.plants__right_row}>
                  <p>{t("Status")}:</p>
                  {totalPlantsBefore2025 > 0 ? <p>
                    <div className={styles.construction}>
                      {t("Starts shortly")}
                    </div>
                  </p> : null}
                  {totalPlantsIn2025 > 0 ? <p>
                    <div className={styles.construction}>
                      {t("Starts shortly")}
                    </div>
                  </p> : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {pathname.includes("/view") ? (
        <>
          <h2>{t("Cultivation status")}</h2>

          <div className={styles.main__statuses}>
            {statuses.map((status, index) => {
              const { text, id } = status;
              return (
                <div key={id} className={styles.main__status_row}>
                  <div>
                    <Status text={text} />
                  </div>
                  <div className={styles.main__status_row_text}>
                    {statusDescription[index].text}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
      {/* {plants && plantBalance != 0 ? (
        <div className={styles.webcam__header}>
          <SectionTitle
            text={`${t("My tokens")} (${Number(plantBalance)
              .toFixed(2)
              .replace(".", ",")}) `}
          />
        </div>
      ) : null} */}

      {/* {plantsTillMay && totalPlantsTillMay > 0 ? (
        <>
          <div className={styles.webcam__label}>
            <img src={tokenNew} alt="nft-1" width={60} />
            <div>
              <h5>{t("NFT")}</h5>
              <span>{t("Sorte 1")}</span>
            </div>
          </div>
          <div className={styles.webcam__wrapper}>
            <Table data={plantsTillMay} hasHeader={false} amountPayout="NFT Sorte 1"/>
          </div>
        </>
      ) : null}
      {plantsJuneJuly && totalPlantsJuneJuly > 0 ? (
        <>
          <div className={styles.webcam__label}>
            <img src={tokenNew} alt="nft-2" width={60} />
            <div>
              <h5>{t("NFT")}</h5>
              <span>{t("Sorte 2")}</span>
            </div>
          </div>
          <div className={styles.webcam__wrapper}>
            <Table data={plantsJuneJuly} hasHeader={false} amountPayout="NFT Sorte 2"/>
          </div>
        </>
      ) : null}

      {plantsFromAugust && totalPlantsFromAugust > 0 ? (
        <>
          <div className={styles.webcam__label}>
            <img src={tokenNew} alt="nft-3" width={60} />
            <div>
              <h5>{t("NFT")}</h5>
              <span>{t("Sorte 3")}</span>
            </div>
          </div>
          <div className={styles.webcam__wrapper}>
            <Table data={plantsFromAugust} hasHeader={false} amountPayout="NFT Sorte 3"/>
          </div>
        </>
      ) : null} */}
      {pathname.includes("/view") || pathname.includes("/dashboard") ? (
        <>
          <div className={styles.webcam__header}>
            <SectionTitle text={t("Webcams")} />
          </div>
          <div className={styles.webcam__wrapper}>
            <div className={styles.webcam__mainCamera}>
              <div className={styles.webcam__mainCamera__offline}>
                {t("Live Camera will be activated soon")}
              </div>
              <img src={CameraVideo} alt="video" />
              <div className={styles.webcam__mainCamera_info}>
                <div className={styles.webcam__mainCamera_info_wrapper}>
                  <div>{t("Live camera")} 1234</div>
                  <div
                    className={styles.webcam__mainCamera_info_separator}
                  ></div>
                  <div>{t("sector")} 002 </div>
                </div>
                <div className={styles.webcam__mainCamera_info_wrapper}>
                  <div>{t("local time")}</div>
                  <div>{t("9:38 AM")}</div>
                </div>
              </div>
            </div>
            {/* <div className={cn(styles.webcam__tabs, styles.webcam__tabsMob)}>
            <div
              className={cn(
                styles.webcam__tab,
                tab === 1 ? styles.webcam__tab_active : ""
              )}
              onClick={() => setTab(1)}
            >
              <LikeIcon />
              <div>{t("My tokens")}</div>
              <div>(0)</div>
            </div>
            <div
              className={cn(
                styles.webcam__tab,
                tab === 2 ? styles.webcam__tab_active : ""
              )}
              onClick={() => setTab(2)}
            >
              <CameraIcon />
              <div>{t("Webcams online")}</div>
              <div>(0)</div>
            </div>
          </div> */}
            <div className={styles.webcam__cameras}>
              <div className={styles.webcam__cameras_item}>
                <div className={styles.webcam__cameras_item__image}>
                  <div className={styles.webcam__cameras_item_offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                  <img src={camera1} alt="camera" />
                </div>

                {/* <img className={styles.webcam__cameras_item_online} src={online} alt="online"/> */}
                <div className={styles.webcam__cameras_item_info}>
                  <div>{t("Live camera")} 1234</div>
                  <div>{t("sector")} 002 </div>
                </div>
              </div>
              <div className={styles.webcam__cameras_item}>
                <div className={styles.webcam__cameras_item__image}>
                  <div className={styles.webcam__cameras_item_offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                  <img src={camera2} alt="camera" />
                </div>
                {/* <img className={styles.webcam__cameras_item_play}  src={play} alt="play"/> */}
                <div className={styles.webcam__cameras_item_info}>
                  <div>{t("Live camera")} 1234</div>
                  <div>{t("sector")} 002 </div>
                </div>
              </div>
              <div className={styles.webcam__cameras_item}>
                <div className={styles.webcam__cameras_item__image}>
                  <div className={styles.webcam__cameras_item_offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                  <img src={camera3} alt="camera" />
                </div>
                {/* <img className={styles.webcam__cameras_item_play}  src={play} alt="play"/> */}
                <div className={styles.webcam__cameras_item_info}>
                  <div>{t("Live camera")} 1234</div>
                  <div>{t("sector")} 002 </div>
                </div>
              </div>
              <div className={styles.webcam__cameras_item}>
                <div className={styles.webcam__cameras_item__image}>
                  <div className={styles.webcam__cameras_item_offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                  <img src={camera4} alt="camera" />
                </div>
                {/* <img className={styles.webcam__cameras_item_play}  src={play} alt="play"/> */}
                <div className={styles.webcam__cameras_item_info}>
                  <div>{t("Live camera")} 1234</div>
                  <div>{t("sector")} 002 </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Main;
