import styles from "./Card.module.scss";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";

const Card = ({ item }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <motion.div
      className={styles.card}
      onHoverStart={() => !isMobile && setShowOverlay(true)}
      onHoverEnd={() => !isMobile && setShowOverlay(false)}
      onClick={() => isMobile && window.open(item.link, '_blank', 'noopener,noreferrer')}
    >
      <AnimatePresence>
        {showOverlay && (
          <motion.a
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </motion.a>
        )}
      </AnimatePresence>

      <img src={item.img} alt={item.id} />
    </motion.div>
  );
};

export default Card;
